import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './About.css'; // Import the CSS file
import Navbar from './Navbar';
import ap from '../assets/Aparna.jpg';
import apq from '../assets/Pooja.JPG';
import { WhatsApp, Phone } from '@mui/icons-material';

const DoctorCard = ({ name, degree, phoneNumber, imgSrc }) => {
  const handleCall = () => {
    window.open(`tel:${phoneNumber}`, '_self');
  };

  return (
    <Card className="card">
      <div className="cover">
        <CardMedia
          component="img"
          image={imgSrc}
          alt={name}
          style={{ borderRadius: '50%' }}
        />
      </div>
      <CardContent>
        <Typography variant="h6" className="name">
          {name}
        </Typography>
        <Typography variant="body1" className="degree">
          {degree}
        </Typography>
        <Button className='callButton' style={{marginTop:'10px',}} onClick={handleCall} variant="outlined" startIcon={<Phone />}>
          {phoneNumber}
        </Button>
      </CardContent>
    </Card>
  );
};

const AboutUs = () => {
  return (
    <>
      <h1 className='doctors'>Our Doctors</h1>
      <div className="container">
        <div className="card-container">
          <DoctorCard
            name="Dr. Aparna Tyagi"
            degree="BDS, MDS, Periodontist and Implantologist"
            phoneNumber="+918076037332"
            imgSrc={ap}
          />
          <DoctorCard
            name="Dr. Pooja Yadav"
            degree="BDS, MDS, Oral and Maxillofacial Surgeon, Hair Transplant Surgeon, Diploma Implantology (Genova, Italy)"
            phoneNumber="+917428993966"
            imgSrc={apq}
          />
        </div>
        {/* <img src={back} alt="Background" className="background-image" /> */}
      </div>
      <div className='reviewContainer'>
        <h2 >What Our Patients Say</h2>
        <p className='reviews'>“Just got my dental problems solved by professional doctors of Smileverse. Definitely a must visit dental clinic to get your natural smile back! Highly recommended!”</p>
        <span className="reviewer-name">Mehak</span>
      </div>
    </>
  );
};

export default AboutUs;
