import React from 'react';
import './Navbar.css'
import { Navbar, Nav } from 'react-bootstrap';
import icon from '../assets/icon.svg'
import { iconButtonClasses } from '@mui/material';
function NavigationBar() {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home">Smileverse Dental Clinic</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"  className="custom-toggler" >
      <img src={icon} alt="Menu" /> {/* Replace with your custom SVG icon */}
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#our-dentist">Our Dentist</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
