import React from "react";
import "./Home.css"; // CSS for HomePage
import backgroundImage from "../assets/logo.png";
import NavigationBar from "./Navbar";

const HomePage = () => {
  const handleBookAppointment = () => {
    const whatsappMessage = encodeURIComponent(
      "Hi, I want to book an appointment."
    );
    const phoneNumber = "+918076037332";
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${whatsappMessage}`;
    window.location.href = whatsappLink;
  };
  return (
    <div className="homepage">
      {/* Navbar Component */}
      <NavigationBar />

      {/* Main Content */}
      <div className="main-content">
        <div className="left-content">
          <img src={backgroundImage} alt="Smileverse Dental Clinic" />
        </div>
        {/* Left Content */}
        <div className="right-content">
          <h1>Welcome to Smileverse Dental Clinic</h1>
          <h2>Your Trusted Dental Care Partner</h2>
          <p>
            We provide diverse dental services, from basic cleanings to advanced
            treatments, ensuring personalized care.
          </p>
          <a href="#contact" onClick={handleBookAppointment} className="btn">
            Schedule an Appointment
          </a>
        </div>

        {/* Right Content (Image) */}
      </div>
    </div>
  );
};

export default HomePage;
