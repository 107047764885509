import React from 'react';
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTooth, FaTeeth, FaSmile,  FaHospital, FaUserMd, FaStethoscope, FaHeart, FaBrain, FaFlask } from 'react-icons/fa';
import './Services.css'; // Import your CSS file for custom styling
import { GiToothbrush } from "react-icons/gi";

const services = [
    { icon: <FaTooth />, title: 'Dental Checkup & X-ray', description: 'Regular checkups and X-rays ensure early detection of dental issues.' },
    { icon: <FaTeeth />, title: 'Teeth Cleaning', description: 'Professional cleaning for healthier gums and fresher breath.' },
    { icon: <FaSmile />, title: 'Crown & Bridges Dentistry', description: 'Restore and enhance your smile with crowns and bridges.' },
    { icon: <GiToothbrush />, title: 'Dental Implants', description: 'Permanent solution for missing teeth, restoring function and aesthetics.' },
    { icon: <FaHospital />, title: 'RCT & dental fillings', description: 'Treatment for tooth decay and root canal issues.' },
    { icon: <FaUserMd />, title: 'Emergency Dentistry', description: 'Immediate care for severe dental pain or trauma.' },
    { icon: <FaStethoscope />, title: 'Orthodontic Braces', description: 'Correct teeth alignment and bite issues with braces.' },
    { icon: <FaHeart />, title: 'Wisdom Tooth Extractions', description: 'Safe removal of impacted wisdom teeth.' },
    { icon: <FaBrain />, title: 'Pyorrhea treatment', description: 'Treatment for gum disease to prevent tooth loss.' },
    { icon: <FaFlask />, title: 'Teeth Whitening', description: 'Brighten your smile with professional teeth whitening.' },
    { icon: <FaBrain />, title: 'Maxillofacial surgery', description: 'Surgical procedures for facial and jaw reconstruction.' },
  ];
  
const Services = () => {
  return (
    <Container className="py-5">
    <h1 className="page-heading">Our Services</h1>
    <div className="card-container">
      {services.map((service, index) => (
        <Card key={index} className="service-card">
          <Card.Body>
            <div className="icon">
              {service.icon}
            </div>
            <Card.Title style={{fontWeight:"bold"}}>{service.title}</Card.Title>
            <Card.Text >{service.description}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  </Container>
  );
};

export default Services;
