// App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('./Components/Home'));
const OurDentistPage = lazy(() => import('./Components/About'));
const ServicesPage = lazy(() => import('./Components/Services'));
const ContactPage = lazy(() => import('./Components/Contact'));
const FixedSidebar = lazy(()=> import ('./Components/FixedSideBar'))
const App = () => {
  return (
    <div>
    <FixedSidebar />
    <div id="home">
      <HomePage />
    </div>
    <div id="our-dentist">
      <OurDentistPage />
    </div>
    <div id="services">
      <ServicesPage />
    </div>
    <div id="contact">
      <ContactPage />
    </div>
  </div>
  );
};

export default App;
