import React from 'react';
import { Container, Card } from 'react-bootstrap';
import { FiMapPin, FiMail, FiPhone } from 'react-icons/fi';
import './Contact.css'; // Import your CSS file for custom styling
const ContactPage = () => {
  const handleOpenMap = () => {
    // Replace '123+Main+Street,+City,+Country' with the address you want to show on the map
    window.open(`https://maps.app.goo.gl/ZdMX1rwqponWkQX28`);
  };
  const handleEmailClick = () => {
    window.location.href = "mailto:smileverse24@gmail.com?subject=Appointment Inquiry&body=Hi, I want to schedule an appointment.";
  };
  const handlePhoneClick = () => {
    window.location.href = "tel:+918076037332";
  };
  const handleOtherPhoneClick = () => {
    window.location.href = "tel:+917428993966";
  };
  return (
    <Container className="py-4">

      <div className="welcome-section">
        <h3 className="welcome-heading">Contact Us</h3>
        <p className="welcome-text">Discover the best dental care in town! Our experienced team is dedicated to providing you with personalized and gentle care to achieve optimal oral health.</p>
      </div>
      <div className="contact-container">
        <Card onClick={handleOpenMap} className="contact-card">
          <FiMapPin className="icon" />
          <Card.Title className="card-title">Address</Card.Title>
          <Card.Text className="card-text" >Unit No. 114, First Floor,
            Sapphire Mall, Sector 49,
            Gurugram, India
          </Card.Text>
        </Card>
        <Card onClick={handleEmailClick} className="contact-card">
          <FiMail className="icon" />
          <Card.Title className="card-title">Email</Card.Title>
          <Card.Text className="card-text" >smileverse24@gmail.com</Card.Text>
        </Card>
        <Card className="contact-card">
          <FiPhone className="icon" />
          <Card.Title className="card-title">Phone</Card.Title>
          <Card.Text className="card-text" onClick={handlePhoneClick} >+918076037332</Card.Text>
          <Card.Text className="card-text" onClick={handleOtherPhoneClick}>+917428993966</Card.Text>
        </Card>
      </div>
    </Container>

  );
};

export default ContactPage;
